<template>
  <a-spin :spinning="isLoading">
    <sdPageHeader :title="__('cargoMatching', 'Kargo Eşleştirme')" />
    <Main>
      <sdCards :title="__('completeTheRelevantMatch', 'İlgili Eşleştirmeyi Tamamlayınız')">
        <a-row
          v-if="remote_shipping_companies.length === 0"
          type="flex"
          justify="center"
          :gutter="12"
          align="top"
        >
          <a-col :span="16">
            <a-button
              type="dashed"
              ghost
              block
            >
              {{__("cargoCompanyNotFoundForCargong", "Siparişlerin alındığı sisteminizde kargo firması bulunamadı.")}}
            </a-button>
          </a-col>
          <a-col :span="16">
            <img
              alt="example"
              src="@/img/company-not-found.png"
              style="width: 100%;"
            >
          </a-col>
        </a-row>
        <a-row
          v-else-if="pageId == 0 && remote_shipping_companies.length !== 0 && customer_shipping_companies.length === 0"
          type="flex"
          justify="center"
          :gutter="12"
          align="top"
        >
          <a-col :span="16">
            <a-button
              type="dashed"
              ghost
              block
            >
              {{__("pleaseAddShippingCompanyFirst", "Lütfen önce kargo firması ekleyiniz")}}
            </a-button>
          </a-col>
          <a-col :span="16">
            <img
              alt="example"
              src="@/img/company-not-found.png"
              style="width: 100%;"
            >
          </a-col>
        </a-row>
        <a-row
          v-else
          type="flex"
          justify="center"
          :gutter="24"
          align="top"
        >
          <a-col :span="12">
            <table justify="center">
              <th>
                <h4 style="color:#1666dd;padding-right: 10px">
                  {{__("integrationCargo", "Entegrasyon Kargoları")}}
                </h4>
              </th>
              <th v-if="pageId == 0">
                <h4 style="color: #f59f19;padding-left: 10px;">
                  {{__("definedCargo", "Tanımlanan Kargolar")}}
                </h4>
              </th>
              <th v-if="pageId == 1">
                <h4 style="color: #f59f19;padding-left: 10px;">
                  {{__("contractedShippingCompany", "Anlaşmalı Kargo Firması")}}
                </h4>
              </th>
              <tr
                v-for="item1 in remote_shipping_companies"
                :key="item1.code"
              >
                <td style="padding-right: 10px">
                  {{ item1.name }}
                </td>
                <td v-if="pageId == 0" style="border-left: 1px #af8f34 dashed;padding-left: 10px;">
                  <a-select
                    v-model:value="item1.value"
                    style="width: 300px"
                    @focus="focus"
                    @change="modalMapChange(item1.code, $event)"
                  >
                    <a-select-option
                      v-for="item2 in customer_shipping_companies"
                      :key="item2.id"
                      :value="item2.id"
                    >
                      {{ item2.title }}
                    </a-select-option>
                  </a-select>
                </td>
                <td v-if="pageId == 1" style="border-left: 1px #af8f34 dashed;padding-left: 10px;">
                  <a-select
                      v-model:value="item1.tk_value"
                      style="width: 300px"
                      @focus="focus"
                      @change="modalMapChange(item1.code, $event)"
                  >
                    <a-select-option
                        v-for="item2 in contract_companies"
                        :key="item2.id"
                        :value="item2.id"
                    >
                      {{ item2.title }}
                    </a-select-option>
                  </a-select>

                </td>
              </tr>
              <tr v-if="remote_shipping_companies.length !== 0">
                <td
                  colspan="3"
                  style="padding-top: 10px"
                >
                  <a-button
                    type="primary"
                    html-type="submit"
                    block
                    @click="mapFormClick"
                  >
                    {{__("save", "Kaydet")}}
                  </a-button>

                  <a-button v-if="pageId == 1"
                      type="dashed"
                      html-type="button"
                      @click="showClientRecipeForm"
                      style="margin-top: 10px; float:right">
                    {{__('submit_recipe', 'Fiyat Tarifesini Gönder')}}
                  </a-button>

                </td>
              </tr>
            </table>
          </a-col>
          <a-col :span="12">
            <img
              alt="example"
              src="@/img/company-map.png"
              style="width: 100%;"
            >
          </a-col>
        </a-row>
      </sdCards>
    </Main>
  </a-spin>
  <a-modal
      v-model:visible="show_client_recipe_form"
      :title="__('submit_recipe', 'Fiyat Tarifesi Gönder')"
      :confirm-loading="save_client_recipe_form"
      :ok-text="__('submit', 'Gönder')"
      :ok-disabled="selectedClientCompanies.length == 0"
      :ok-button-props="{disabled:selectedClientCompanies.length == 0}"
      :cancel-text="__('cancel', 'İptal')"
      @ok="updateClientRecipe"
      @cancel="show_client_recipe_form = false"
  >
    <a-spin :spinning="save_client_recipe_form">
      <a-form layout="vertical">
        <a-select
            v-model:value="selectedClientCompanies"
            mode="multiple">
          <a-select-option
              v-for="item in remote_shipping_companies"
              v-show="item.tk_value"
              :key="item.code"
              :value="item.code" >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { Main } from '@/view/styled'

export default {
  components: {
    Main,
  },
  data() {
    return {
      isLoading: false,
      modalCargoMap: {},
      customer_shipping_companies: [],
      remote_shipping_companies: [],
      pageId : null,
      contract_companies : [],
      projects : [],
      data : {},
      show_client_recipe_form : false,
      save_client_recipe_form : false,
      selectedClientCompanies: []
    }
  },
  mounted() {
    this.pageId = parseInt(localStorage.getItem("jwtContract") || 0);//0:cargong, 1: tsoft-kargo
    this.isLoading = true
    this.$restMethods.postForCargongWebApp('/shipping-company-map-open').then(response => {
      if ( ! response.hasError()) {
        const data = response.getData()
        this.remote_shipping_companies = data.remote_shipping_companies
        this.customer_shipping_companies = data.customer_shipping_companies
        this.data = data;
        this.contract_companies = data.contract_companies;
        this.projects = data.projects.map(function(item){return item.project_id});
        //project: cargong:2, tsoft kargo: 1
      }
      this.isLoading = false
    })
  },
  methods: {
    mapFormClick() {
      if(!this.modalCargoMap || Object.keys(this.modalCargoMap).length == 0){
        return;
      }
      let postData = [], pItem;
      for(let i in this.modalCargoMap){
        pItem = {ext_code: i};
        if(this.pageId == 0){
          pItem.cs_company_id = this.modalCargoMap[i];
        }
        else if(this.pageId == 1){
          pItem.tk_cs_company_id = this.modalCargoMap[i];
        }
        postData.push(pItem);
      }
      this.isLoading = true;
      this.$restMethods.postForCargongWebApp('/shipping-company-map-submit', { items: postData }).then(response => {
      // this.$restMethods.postForCargongWebApp('/shipping-company-map-submit', { data: this.modalCargoMap }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(this.$t.saveSuccessful)
        }
        this.isLoading = false
      })
    },
    updateClientRecipe() {
      if(!this.selectedClientCompanies || this.selectedClientCompanies.length == 0){
        return;
      }
      this.save_client_recipe_form = true;
      this.$restMethods.postForTSoftKargoWebApp('/update-client-cargo-amount', { items: this.selectedClientCompanies }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(this.__("saveSuccessful", "Başarılı"))
        }
        this.save_client_recipe_form = false
      })
    },
    showClientRecipeForm() {
      this.selectedClientCompanies = [];
      // for(let rCompany of this.remote_shipping_companies){
      //     if(rCompany.tk_value){
      //       this.selectedClientCompanies.push(rCompany.code);
      //     }
      // }
      this.show_client_recipe_form = true;
    },
    modalMapChange(remoteCode, localId) {
      this.modalCargoMap[remoteCode] = localId
    },
  },
}
</script>
